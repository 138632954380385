/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useState,useEffect} from "react";
import { GetCookies } from "../../_helpers/Cookies";
import { FaCog, FaRegUser } from 'react-icons/fa';
import { toAbsoluteUrl } from "../../_helpers";
// import {ApiDataNatural} from "./ApiDataTable/ApiDataNatural"
// import {ApiDataLab} from "./ApiDataTable/ApiDataLab"
import { ApiDataTable } from "./ApiDataTable/APIDataTable";
import { useHistory } from "react-router-dom";
import {
    Typography
  } from "@material-ui/core";
  import PropTypes from "prop-types";
  import SelectFieldCurrency from "./ApiSetup/FormFields/SelectFieldCurrency";
  import { NODE_API_URL,BASE_URL } from "../../../env_config";
  import Cookies from "js-cookie";
  import axios from "axios";
  import { TextField} from '@material-ui/core';
  import { TaxPopUp } from "../Popup/TaxPopUp";
  import {
    FaRegEdit,
} from "react-icons/fa";
import { SwalWarn } from "../Popup";

  function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
  }
  
  TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
  };

export const RulePage = (props) => {
    const history = useHistory()

    const [inr,setInr] = useState(0)
    const [usd,setUsd] = useState(0)
    const [cad,setCad] = useState(0)
    const [aud,setAud] = useState(0)
    const [hkd,setHkd] = useState(0)
    const [cny,setCny] = useState(0)
    const [eur,setEur] = useState(0)
    const [gbp,setGbp] = useState(0)
    const [nzd,setNzd] = useState(0)
    const [jpy,setJpy] = useState(0)
    const [chf,setChf] = useState(0)
    const [sgd,setSgd] = useState(0)
    const [thb,setThb] = useState(0)
    const [myr,setMyr] = useState(0)
    const [bnd,setBnd] = useState(0)
    const [dkk,setDkk] = useState(0)
    const [sek,setSek] = useState(0)
    const [currencyvalue,setCurrencyValue] = useState("")
    const [supplieron,setSupplierOn] = useState(null)
    const [taxname,setTaxName] = useState("")
    const [taxvalue,setTaxValue] = useState(0)
    const [edittax,setEditTax] = useState(false)
    const [updatestate,setUpdateState] = useState(false) 
    useEffect(() => {
        console.log("conversionratesuseEffect")
        GetCookies("conversionrates").then(response => {
            // console.log(response,"Conversionresponse")
            if(response && response[0]){
                const currencies = JSON.parse(response)
                // console.log(currencies,"currencies")
                setInr(Math.round(currencies[0].cur_inr*100)/100)
                setUsd(Math.round(currencies[0].cur_usd*100)/100)
                setCad(Math.round(currencies[0].cur_cad*100)/100)
                setAud(Math.round(currencies[0].cur_aud*100)/100)
                setHkd(Math.round(currencies[0].cur_hkd*100)/100)
                setCny(Math.round(currencies[0].cur_cny*100)/100)
                setEur(Math.round(currencies[0].cur_eur*100)/100)
                setGbp(Math.round(currencies[0].cur_gbp*100)/100)
                setNzd(Math.round(currencies[0].cur_nzd*100)/100)
                setJpy(Math.round(currencies[0].cur_jpy*100)/100)
                setChf(Math.round(currencies[0].cur_chf*100)/100)
                setSgd(Math.round(currencies[0].cur_sgd*100)/100)
                setThb(Math.round(currencies[0].cur_thb*100)/100)
                setMyr(Math.round(currencies[0].cur_myr*100)/100)
                setBnd(Math.round(currencies[0].cur_bnd*100)/100)
                setDkk(Math.round(currencies[0].cur_dkk*100)/100)
                setSek(Math.round(currencies[0].cur_sek*100)/100)
            }
        })
        setCurrencyValue(props.customeriframeorapi.api_currency)
        setTaxName(props.customeriframeorapi.api_taxname)
        setTaxValue(props.customeriframeorapi.api_taxvalue)
        axios({
            method: "POST",
            url: `${NODE_API_URL}/EUR/fetch-supplier-step`,
            headers: {
              "Content-Type": "application/json",
               "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
          },
            data: {
                "api_id":props.customeriframeorapi.api_id
            },
        }).then(supreq => {
            if(supreq && supreq.data){
                if(supreq.data !== "Please Turn On Suppliers"){
                    setSupplierOn(supreq.data)

                }
            }
        }).
        catch(error => {
          if(error?.response?.data?.message === "UnAuthorized!"){
            var cookies = document.cookie.split(";");
          for (var i = 0; i < cookies.length; i++) {
            if(cookies[i] && !cookies[i].includes("view")){
              var equals = cookies[i].indexOf("=");
              var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
              document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }
          }
          
          window.location.replace(`${BASE_URL}/logout`);
        }
        })
    },[props.customerapi,updatestate,props.customeriframeorapi])
    const muti_currency_data = [
      {
        value: 'INR',
        label: `INR  - ${inr.toFixed(2)}`
      },
        {
          value: 'USD',
          label: `USD $ - ${usd.toFixed(2)}`
        },
        {
          value: 'CAD',
          label: `CAD $ - ${cad.toFixed(2)}`
        },
        {
          value: 'AUD',
          label: `AUD $ - ${aud.toFixed(2)}`
        },
        {
            value: 'HKD',
            label: `HKD $ - ${hkd.toFixed(2)}`
          },
          {
            value: 'CNY',
            label: `CNY ¥ - ${cny.toFixed(2)}`
          },
          {
            value: 'EUR',
            label: `EUR € - ${eur.toFixed(2)}`
          },
          {
              value: 'GBP',
              label: `GBP £ - ${gbp.toFixed(2)}`
            },
            {
              value: 'NZD',
              label: `NZD $ - ${nzd.toFixed(2)}`
            },
            {
              value: 'JPY',
              label: `JPY ¥ - ${jpy.toFixed(2)}`
            },
            {
              value: 'CHF',
              label: `CHF ₣ - ${chf.toFixed(2)}`
            },
            {
              value: 'SGD',
              label: `SGD $ - ${sgd.toFixed(2)}`
            },
            {
              value: 'THB',
              label: `THB ฿ - ${thb.toFixed(2)}`
            },
            {
              value: 'MYR',
              label: `MYR RM - ${myr.toFixed(2)}`
            },
            {
              value: 'BND',
              label: `BND $ - ${bnd.toFixed(2)}`
            },
            {
              value: 'DKK',
              label: `DKK kr. - ${dkk.toFixed(2)}`
            },
            {
              value: 'SEK',
              label: `SEK kr - ${sek.toFixed(2)}`
            }
      ];

      const CancelTax = () => {
        setEditTax(false)
        setUpdateState(!updatestate)
      }
      const SaveTax = () => {
        setEditTax(false)
        axios({
          method: "POST",
          url: `${NODE_API_URL}/EUR/update-Api-CurrencyAndTax`,
          headers: {
            "Content-Type": "application/json",
             "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
        },
          data: {
              "columnname":"tax",
              "api_taxname":taxname,
              "api_taxvalue":taxvalue
          },
      }).then(supreq => {
          if(supreq && supreq.data){
              if(supreq.data === "Data Updated Successfully"){
                setUpdateState(!updatestate)
                props.setConversionCookies(!props.conversioncookies)
              }
          }
      }).
      catch(error => {
        if(error?.response?.data?.message === "UnAuthorized!"){
          var cookies = document.cookie.split(";");
        for (var i = 0; i < cookies.length; i++) {
          if(cookies[i] && !cookies[i].includes("view")){
            var equals = cookies[i].indexOf("=");
            var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
          }
        }
        
        window.location.replace(`${BASE_URL}/logout`);
      }
      })
      }
      const [swalWarnOpen,setSwalWarnOpen] = useState(false)
      const [popupcurrency,setPopupCurrency] = useState("")
      const OpenPopup = (value) => {
        setSwalWarnOpen(true)
        setPopupCurrency(value)
      }
      const UpdateCurrency = (value) => {
        console.log("UpdateCurrency")
        axios({
          method: "POST",
          url: `${NODE_API_URL}/EUR/update-Api-CurrencyAndTax`,
          headers: {
            "Content-Type": "application/json",
             "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
        },
          data: {
              "columnname":"currency",
              "api_currency":value
          },
      }).then(supreq => {
          if(supreq && supreq.data){
              if(supreq.data === "Data Updated Successfully"){
                setUpdateState(!updatestate)
                props.setConversionCookies(!props.conversioncookies)
              }
          }
      }).
      catch(error => {
        if(error?.response?.data?.message === "UnAuthorized!"){
          var cookies = document.cookie.split(";");
        for (var i = 0; i < cookies.length; i++) {
          if(cookies[i] && !cookies[i].includes("view")){
            var equals = cookies[i].indexOf("=");
            var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
          }
        }
        
        window.location.replace(`${BASE_URL}/logout`);
      }
      })
      }
    return (
        <>
        <SwalWarn show={swalWarnOpen} message={`Are you sure about changing the currency to ${popupcurrency} for the diamond feed?`} onHide={() => setSwalWarnOpen(false)} onClose={() => setSwalWarnOpen(false)} responseOnOk={() => UpdateCurrency(popupcurrency)}/>
        <TaxPopUp show={edittax} onHide={() => CancelTax()} onClose={() => CancelTax(false)} responseOnOk={() => SaveTax()} setTaxName={setTaxName} setTaxValue={setTaxValue} taxname={taxname} taxvalue={taxvalue}/>
            {props.customeriframeorapi.api_plan !== "API_V2"?<div className="card shadow-none card-custom">
                <div className="card-header">
                  <h3 className="card-title">API Dashboard</h3>
                  <div className=" card-toolbar d-flex align-items-center">                            
                      <div className="">
                        <SelectFieldCurrency
                          name={"currency"}
                          label={"Select Currency"}
                          style={{minWidth: 200}}
                          data={muti_currency_data}
                          value={currencyvalue}
                          onChange={(e) => currencyvalue?OpenPopup(e.target.value):UpdateCurrency(e.target.value)}
                          // disabled={props.customerapi.length > 0}
                        />
                      </div>
                      <div className="mr-2">
                            <TextField
                            variant="outlined"
                            type="text"
                            name={"Tax"}
                            label={"Tax(%)"}
                            style={{minWidth: 200}}
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            // onChange={(e) => setTaxValue(e.target.value)}
                            disabled={true}
                            value={taxvalue && taxname?`Inclusive ${taxname} - ${taxvalue}%`:"Exclusive Tax"}
                          />
                          
                          {!edittax?<button
                            className="btn btn-text-dark btn-hover-light-dark"
                            type="button"
                            onClick={() => setEditTax(true)}
                          >
                            <FaRegEdit className="font-size-h5" />
                          </button>:""}
                          </div>
                          <button className="btn btn-text-dark btn-hover-light-dark" 
                        onClick={() => history.push({
                            pathname: "/supplier-management",
                            // state: decompress(res.data.ProductDetails),
                        })}> <FaRegUser/> Supplier Management</button>    
                      {props.customeriframeorapi.feed_type === "IFrame Plug-in" ?
                        <button className="btn btn-text-dark btn-hover-light-dark" 
                          onClick={() => history.push({
                            pathname: "/settings",
                            state: "pluginsetting",
                            // state: decompress(res.data.ProductDetails),
                          })}><FaCog className="mr-2" />{props.language.DIAMOND_FEED_NATURAL_COLORMARKUP_IFRAMESETTING}</button> :""}
                      {props.customeriframeorapi.feed_type === "API" ? 
                        <button className="btn btn-text-dark btn-hover-light-dark" 
                          onClick={() => history.push({
                            pathname: "/settings",
                            state: "apisetting",
                            // state: decompress(res.data.ProductDetails),
                        })}><FaCog className="mr-2" />{props.language.DIAMOND_FEED_NATURAL_COLORMARKUP_APISETTING}</button>:""}
                  </div>
              </div>
              <div className="card-body bg-white">  
                <ApiDataTable userinfo={props.userinfo} language={props.language} customerapi={props.customerapi} setUpdateData={props.setUpdateData} updatedata={props.updatedata} currencyvalue={currencyvalue} supplieron={supplieron} loader={props.loader} setCustomerAPI={props.setCustomerAPI} customeriframeorapi={props.customeriframeorapi}/>
              </div>
            </div>:
            <div className="card shadow-none card-custom">
                <div className="card-header">
                  <h3 className="card-title">Diamond Feed Center</h3>
                  <div className=" card-toolbar d-flex align-items-center">
                          <button className="btn font-weight-bold btn-square btn-secondary px-8 mr-2" 
                        onClick={() => history.push({
                            pathname: "/supplier-management",
                            // state: decompress(res.data.ProductDetails),
                        })}>Supplier Management</button>    
                      {props.customeriframeorapi.feed_type === "IFrame Plug-in" ?
                        <button className="btn font-weight-bold btn-square btn-secondary px-10" 
                          onClick={() => history.push({
                            pathname: "/settings",
                            state: "pluginsetting",
                            // state: decompress(res.data.ProductDetails),
                          })}><FaCog className="mr-2" />{props.language.DIAMOND_FEED_NATURAL_COLORMARKUP_IFRAMESETTING}</button> :""}
                      {props.customeriframeorapi.feed_type === "API" ? 
                        <button className="btn font-weight-bold btn-square btn-secondary px-8 mr-2" 
                          onClick={() => history.push({
                            pathname: "/settings",
                            state: "apisetting",
                            // state: decompress(res.data.ProductDetails),
                        })}><FaCog className="mr-2" />{props.language.DIAMOND_FEED_NATURAL_COLORMARKUP_APISETTING}</button>:""}
                  </div>
              </div>
              <div className="card-body text-right">
                <img className="img-gluid" src={toAbsoluteUrl("/media/General/diamond_feed_approved.svg")} alt="diamond feed" style={{maxWidth:"800px"}}/>
                {/* <SVG  /> */}
              </div>
              {/* <div className="api-home-svg api-success"><SVG src={toAbsoluteUrl("/media/General/diamond_feed_request.svg")} /></div> */}
            </div>}
        </>
    );
};

import React, { useState,useRef } from 'react';
import {Snackbar,IconButton,makeStyles,SnackbarContent} from '@material-ui/core';
import {OverlayTrigger,Tooltip,Overlay} from "react-bootstrap";
import {toAbsoluteUrl} from "../../../_helpers";
import { FaExternalLinkAlt,FaInfoCircle} from "react-icons/fa";
import { BsLightningFill } from "react-icons/bs";
// import {GiCutDiamond} from "react-icons/gi"
import {Modal,Button} from "react-bootstrap";
// import { useHistory } from "react-router-dom";
import VideoIframe from "../../detailPage/VideoIframe";
import SVG from "react-inlinesvg";
import ErrorIcon from "@material-ui/icons/Error";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import green from "material-ui/colors/green";
import red from "material-ui/colors/red";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
// import { HOLDORBUY } from '../../../../env_config';  
// import {ConfirmGoods,HoldPopup} from '../Popup'
import { SwalWarn } from "../../Popup";
import { TracklistStone } from '../../Popup/TracklistPopup';
import { ImageExpandPopupCommon } from '../../widgets/tiles/ImageExpandPopupCommon';
import { VideoExpandPopupCommon } from '../../widgets/tiles/VideoExpandPopupCommon';
import { MainImageExpandedDetailsPopup } from '../../widgets/tiles/MainImageExpandedDetailsPopup';
import { CopyUrlAlert } from '../../Popup/CopyUrlAlert';
import CertificateLink from '../../CertificateLink';
export default function ExpandedDetais({row,conversionrate,symbol,language,BuyDiamond,HoldDiamond,symbolname,userinfo}){
    const [show,setShow] = useState(false)
    const [diamonddetails,setDiamondDetail] = useState([])
    const userData = React.useMemo(() => localStorage.getItem("userData"), []);
    const user = JSON.parse(userData);
    const [overlyShow, setOverlyShow] = useState(false);
    const overlyTarget = useRef(null);

    const [showVideo, setShowVideo] = React.useState(false);
    const [showAssetImg, setShowAssetImg] = React.useState(false);
    const [showArrowImg, setShowArrowImg] = React.useState(false);
    const [showHeartImg, setShowHeartImg] = React.useState(false);
    const [swalWarnOpen,setSwalWarnOpen] = useState(false)
    const [alertMessage,setAlertMessage] = useState()
    const [Toast] = useState(false)
    const [wishlistmessage] = useState(null)
    // const history = useHistory()
    const GenerateTitle = (row) => {
        let title = ""
        // if(row.lab){
        //     title += row.lab
        // }
        if(row.shape){
            title += " "
            title += row.shape
        }
        if(row.carat){
            title += " "
            title += row.carat?.toFixed(2) 
        }
        // if(row.fancy_intensity ){
        //     title += " "
        //     title += row.fancy_intensity 
        // }
        if(row.color){
            title += " "
            title += row.color
        }
        if(row.clarity){
            title += " "
            title += row.clarity
        }

        if(row.cut){
            title += " "
            title += row.cut
        }
       
        if(row.polish){
            title += " "
            title += row.polish
        }

        if(row.symmetry){
            title += " "
            title += row.symmetry
        }

        if(row.fluorescence){
            title += " "
            title += row.fluorescence
        }
        return title
    }
    const RouteToDetail = (row) => {
        const url = `/detail/${row.diamondType}-${row.stockID}`;
        window.open(url, '_blank');
    };
    const DetailSemiAprrove = () => {
        // setSemiAprroveModal(true)
        setSwalWarnOpen(true)
        setAlertMessage("Your account is under Lite Mode, to get further access please contact your sales representative. Thank you")
    }
    
    function handleClose(event, reason) {
        setShowVideo(false);
        setShowHeartImg(false);
        setShowAssetImg(false);
        setShowArrowImg(false);
    }

    function openHeartModal() {
        setShowHeartImg(true)
    }

    function openAssetModal() {
        setShowAssetImg(true)
    }

    function openArrowModal() {
        setShowArrowImg(true)
    }
  
    const variantIcon = {
        success: CheckCircleIcon,
        error: ErrorIcon,
        warning: ErrorIcon,
    };
    const useStyles = makeStyles((theme) => ({
        close: {
            padding: theme.spacing(0.5),
        },
        success: {
            backgroundColor: green[600],
        },
        error: {
            backgroundColor: red[600],
        },
    }));
    function MySnackbarContentWrapper(props) {
        const classes = useStyles();
        const { className, message, onClose, variant, ...other } = props;
        const Icon = variantIcon[variant];
    
        return (
            <SnackbarContent
                className={clsx(classes[variant], className)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <Icon
                            className={clsx(classes.icon, classes.iconVariant)}
                        />
                        {message}
                    </span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={onClose}
                    >
                        <CloseIcon className={classes.icon} />
                    </IconButton>,
                ]}
                {...other}
            />
        );
    }

    const shadeMappings = {
        "none": { label: "No.SH", tooltip: "Shade: NONE" },
        "brown": { label: "BR", tooltip: "Shade: Brown" },
        "light brown": { label: "L.BR", tooltip: "Shade: Light Brown" },
        "green": { label: "GR", tooltip: "Shade: Green" },
        "light green": { label: "L.GR", tooltip: "Shade: Light Green" },
        "grey": { label: "GREY", tooltip: "Shade: Grey" },
        "black": { label: "BLK", tooltip: "Shade: Black" },
        "pink": { label: "PINK", tooltip: "Shade: Pink" },
        "blue": { label: "BLUE", tooltip: "Shade: Blue" },
        "mix tinge": { label: "MIX", tooltip: "Shade: Mix Tinge" },
    };
    
    const renderShadeLabel = (shade) => {
        const mapping = shadeMappings[shade ? shade.toLowerCase() : ""];
        if (!mapping) return null;
    
        return (
            <span>
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip">{mapping.tooltip}</Tooltip>}
                >
                    <span className={`label label-light-primary label-light-shade_${shade.toLowerCase().replace(' ', '_')} label-pill label-inline label-sm mr-1 font-weight-bold px-2`}>{mapping.label}</span>
                </OverlayTrigger>
            </span>
        );
    };
   
    function numberWithCommas(x) {
        return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    
    const AddToTracklist = (row) => {
        setShow(true)
        setDiamondDetail([{
            "Certi_NO":row.certificate,
            "diamond_type":row.diamondType,
            "tracklisted_price_usd":row.ourPrice,
            "tracklisted_price":Math.round(row.ourPrice * conversionrate * 100)/100
        }])
    }
    return(
       <>
       <TracklistStone show={show} setShow={setShow} language={language} diamonddetails={diamonddetails} userinfo={userinfo} symbolname={symbolname} conversionrate={conversionrate}/>
        <div className={`expandedDetails ${row.diamondType === "N" ? "natTab" : "labTab" } ${row.isfancy ? "fancyExpand" : "" }`} >
            <div className="row pt-2">
                <div className="col-3 d-flex">
                    <ul className="img_thumb_box mt-4 ml-5 pl-0">
                        
                            <ImageExpandPopupCommon data={row}/>
                            <VideoExpandPopupCommon  data={row} userinfo={userinfo}/>
                        {row.heart ? (
                            <li className="img_thumb cursor-pointer" onClick={() => openHeartModal()}>
                                {/* <img  alt="img thumb" src="https://assets.diamondsoncall.com/assets/front/images/pd-5.png"/> */}
                                <SVG  width="40" src={toAbsoluteUrl("/media/General/heartImage.svg")} />
                            </li>
                            
                        ):('')}
                        {row.arrow ? (
                            <li className="img_thumb cursor-pointer" onClick={() => openArrowModal()}>
                                <SVG  width="40" src={toAbsoluteUrl("/media/General/arrowImage.svg")} />
                                {/* <img  alt="img thumb" src="https://assets.diamondsoncall.com/assets/front/images/pd-6.png"/> */}
                            </li>
                        ):('')}
                        {row.asset ? (
                            <li className="img_thumb cursor-pointer" onClick={() => openAssetModal()}>
                                <SVG  width="40" src={toAbsoluteUrl("/media/General/assetIcon.svg")} />
                                {/* <img  alt="img thumb" src="https://assets.diamondsoncall.com/assets/front/images/pd-3.png"/> */}
                            </li>
                        ):('')}
                    </ul>
                    <div className="img_box mt-4 ribbon ribbon-top">
                    {row.diamondType === "N" || row.diamondType === "W" ? null: <div className="ribbon-target custom-top-ribbon lab-ribbon" style={{top:"-2px",right:"20px"}} >Lab</div> }
                        <MainImageExpandedDetailsPopup data={row}/>
                    </div>
                </div>
                <div className="col-3">
                    <div className="row">
                        <div className="col-12 mt-3 mb-2 d-flex align-items-center">
                            <div className="mr-2 border-right font-size-sm">
                            <>
                                        {row.lab}:<CertificateLink Certificate_link={row.Certi_link} lab={row.lab} certificate={row.certificate} hideLabName withExternalLinkIcon className={`text-dark mx-2 text-left`} />
                                        <CopyUrlAlert text={row.certificate}/>
                                    </>
                               
                            </div>
                            <div className='font-size-sm mr-2'><div className="d-inline-block mr-2 cursor-pointer" onClick={(e) => RouteToDetail() } >{row.diamondType + "-" + row.stockID}</div>
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                {
                                                row.availability === 'Memo' || row.availability === 'Hold'
                                                    ? `Business Process`
                                                    : row.availability === "Guaranteed"
                                                    ? `Guaranteed`
                                                    : row.availability === "Not Available"
                                                    ? `Not Available`
                                                    : row.availability === "Subject to Prior sale"
                                                    ? `Subject to Prior sale`
                                                    : `Unknown`
                                                }
                                            </Tooltip>
                                        }
                                    >
                                        <img
                                            className="img-fluid availIco mr-2"
                                            alt={row.availability}
                                            src={toAbsoluteUrl(`/media/svg/${
                                                row.availability === 'Memo' || row.availability === 'Hold'
                                                    ? `availability_primary.svg`
                                                    : row.availability === "Guaranteed"
                                                    ? `availability_success.svg`
                                                    : row.availability === "Not Available"
                                                    ? `availability_danger.svg`
                                                    : row.availability === "Subject to Prior sale"
                                                    ? `availability_warning.svg`
                                                    : `availability_blank_white.svg`
                                            }`)}
                                        />
                                    
                                    </OverlayTrigger>
                                </span>
                            </div>
                        </div>
                        <div className="col-10 align-self-center">
                            <span className="text-dark font-size-h4 text-capitalize font-weight-bold">{GenerateTitle(row)}</span>
                        </div>
                        <div className="col-2">
                            <OverlayTrigger
                                placement="top"
                                className="text-capitalize"
                                overlay={<Tooltip id="tooltip">Click to Tracklist</Tooltip>}
                            >
                                <span className="btn btn-light text-dark btn-icon" onClick={() => AddToTracklist(row)}>
                                    <SVG className="ol text-dark" src={toAbsoluteUrl("/media/svg/icons/Custom/Track.svg")} height="23px" width="23px" />
                                </span>
                            </OverlayTrigger>
                            
                        </div>
                        <div className='col-12 advTag mb-3'>
                            {/* shade */}
                            {renderShadeLabel(row.shade)}
                            
                            { row.milky  && row.milky .toLowerCase() === "no milky" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                NO MILKY
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-no_milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">NO.ML</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }

                            { row.milky  && row.milky .toLowerCase() === "luster low" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                Luster Low
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-luster_low label-pill label-inline label-sm  mr-1 font-weight-bold px-2">LL</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }

                            { row.milky  && row.milky .toLowerCase() === "light milky" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                LIGHT MILKY
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-light_milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">L.ML</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }

                            { row.milky  && row.milky .toLowerCase() === "milky" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                MILKY
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">H.ML</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }

                            {/* eyeclean */}
                            { row.eyeClean  && row.eyeClean .toLowerCase() === "yes" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                100% Eye Clean
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-info label-pill label-inline label-sm  mr-1 font-weight-bold px-2">100% EC</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }
                            { row.eyeClean  && row.eyeClean .toLowerCase() === "no" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                No Eye Clean
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-danger label-pill label-inline label-sm  mr-1 font-weight-bold px-2">EC: NO</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }

                            {/* growthtype */}
                            { row.diamondType === "L" && (row.growthType && row.growthType === "CVD") ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                CVD
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-success label-pill label-inline label-sm  mr-1 font-weight-bold px-2">CVD</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }
                            { row.diamondType === "L" && (row.growthType && row.growthType === "HPHT") ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                HPHT
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-success label-pill label-inline label-sm  mr-1 font-weight-bold px-2">HPHT</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }
                            
                            { (row.diamondType === "L" ) && (row.treatment && row.treatment.toLowerCase() === "as grown") ? 
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                As Grown
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-success label-pill label-inline label-sm  font-weight-bold px-2">As-G</span>
                                    </OverlayTrigger>
                                </span>
                            : 
                                null
                            }
                            {/* natural treated */}
                            { (row.diamondType === "N" || row.diamondType === "W") && (row.treatment  && row.treatment .toLowerCase() !== "none")  ? 
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                Treated
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-danger label-pill label-inline label-sm  font-weight-bold px-2">Tx</span>
                                    </OverlayTrigger>
                                </span>
                            : 
                                null
                            }
                        </div>
                       
                        {!row.supplierName ? (
                            ""
                        ):(
                            <div className="col-12">
                                { userinfo?.v_customer?.toString() ==="7" || userinfo?.v_customer?.toString() ==="11" ? (
                                    <span className="font-size-sm text-dark mr-5"><span className="text-muted font-size-sm  text-capitalize">{language.MUI_SUPPLIER} :</span> ***************</span>
                                ) :(
                                    <span className="font-size-sm text-dark mr-5"><span className="text-muted font-size-sm  text-capitalize">{language.MUI_SUPPLIER} :</span> {row.supplierName}</span>
                                )}
                                 <span className="text-dark font-size-sm"> 
                                        <span className='text-muted'>{language.SEARCHLIST_LOCATION}:</span> {row.location ? row.location : "-"}
                                        {row.location ? (
                                            <OverlayTrigger
                                                placement="top"
                                                className="text-capitalize"
                                                overlay={
                                                    <Tooltip id="tooltip">{row.location}</Tooltip>
                                                }
                                            >
                                                <img
                                                    className="flagIco ml-1"
                                                    alt={row.value}
                                                    src={toAbsoluteUrl(
                                                        `/media/map/${row.location.toLowerCase()}.png`
                                                    )}
                                                    onError={e => {
                                                        e.currentTarget.src =toAbsoluteUrl(
                                                            `/media/map/other.png`
                                                            )
                                                        }}
                                                />
                                            </OverlayTrigger>
                                        ) : (
                                            null
                                        )}
                                    </span>
                            </div>
                        )}
                        
                        <div className="col-12 mt-3 d-flex">
                            <span className="font-size-lg text-dark"><span className="font-size-sm text-muted text-capitalize">{language.MUI_LISTPRICE}: </span>${row.listPrice?row.listPrice:"0000"}</span>
                            <span className="font-size-lg text-dark ml-8"><span className="font-size-sm text-muted text-capitalize">{language.SEARCHGRID_SHIPPINGDAYS}: </span>{<> {row.shippingDays }{" "}{language.MUI_DAYS} </>}</span>
                        </div>
                        <div className="col-12 mt-3">
                            <div className="row"> 
                            {!row.supplierName?(
                                ""
                            ) : (
                                <div className="col-6">
                                    <div className="price_border_right"></div>
                                    <div className="px-0">
                                        <h5>
                                            {language.MUI_SUPPLIERPRICE}
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            Price Provided by Supplier
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="ml-2 text-hover-primary cursor-pointer"><FaInfoCircle /></span>
                                                </OverlayTrigger>
                                        </h5>
                                        <hr className="w-25 ml-0 my-2"/>
                                        {row.supplierDiscount ?row.supplierDiscount .includes('-')?
                                        <div className="d-flex small text-danger align-items-center font-size-lg">{row.supplierDiscount }% Off<span className="ml-8 text-dark">({symbolname})</span></div>:
                                        <div className="d-flex small text-success align-items-center font-size-lg">{row.supplierDiscount }% PR<span className="ml-8 text-dark ">({symbolname})</span></div>
                                        :<div className="d-flex small text-danger align-items-center font-size-lg">0.00% Off<span className="ml-8 text-dark">({symbolname})</span></div>} 
                                        {
                                            conversionrate !== 1 ? <>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            ${numberWithCommas(row.supplierPrice )}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="h1">
                                                        <><span className="small">{symbol}</span >{numberWithCommas(row.supplierPrice  * conversionrate)}</>
                                                    </span>
                                                </OverlayTrigger>
                                            </> :
                                            <span className="h1">
                                                <><span className="small">{symbol}</span >{numberWithCommas(row.supplierPrice  * conversionrate)}</>
                                            </span>
                                        }
                                        {
                                            conversionrate !== 1 ? <>
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            ${numberWithCommas(row.supplierRate )}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="d-flex small text-dark-50 align-items-center">Per Ct. <span className="font-size-sm text-dark-50 ml-2">{symbol}{numberWithCommas(row.supplierRate  * conversionrate)}</span></span>
                                                </OverlayTrigger>
                                            </> :
                                            <span className="d-flex small text-dark-50 align-items-center">Per Ct. <span className="font-size-sm text-dark-50 ml-2">{symbol}{numberWithCommas(row.supplierRate  * conversionrate)}</span></span>
                                        }
                                    </div>
                                </div>
                            )}
                                <div className="col-6">
                                    <div className="px-0">
                                        <h5 className="position-relative">
                                            {language.MUI_OURPRICE}
                                            {row.certificate  === 0 ? (
                                                ""
                                            ):(
                                                <>
                                                    <span className="ml-2 text-hover-primary cursor-pointer" ref={overlyTarget} onClick={() => setOverlyShow(!overlyShow)}><FaInfoCircle /></span>
                                                    <Overlay target={overlyTarget.current} show={overlyShow} placement="bottom">
                                                        {props => (
                                                            <Tooltip id="overlay-example" {...props}>
                                                            Supplier +  <a href='/transparentpricing' target='_blank'>our charge</a>
                                                            </Tooltip>
                                                        )}
                                                    </Overlay>
                                                </>
                                            )}
                                        </h5>
                                        <hr className="w-25 ml-0 my-2"/>
                                        {row.ourDiscount ?row.ourDiscount .includes('-')?
                                        <div className="d-flex small text-danger align-items-center">{row.ourDiscount }% Off<span className="ml-8 text-dark font-size-lg">({symbolname})</span></div>:
                                        <div className="d-flex small text-success align-items-center">{row.ourDiscount }% PR<span className="ml-8 text-dark font-size-lg">({symbolname})</span></div>
                                        :<div className="d-flex small text-danger align-items-center">0.00% Off<span className="ml-8 text-dark font-size-lg">({symbolname})</span></div>}
                                        
                                        {
                                            conversionrate !== 1 ? <>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            ${numberWithCommas(row.ourPrice)}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="h1">
                                                        <><span className="small">{symbol}</span >{numberWithCommas(row.ourPrice * conversionrate)}</>
                                                    </span>
                                                </OverlayTrigger>
                                            </> :
                                            <span className="h1">
                                                <><span className="small">{symbol}</span >{numberWithCommas(row.ourPrice * conversionrate)}</>
                                            </span>
                                        }  
                                        {
                                            conversionrate !== 1 ? <>
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            ${numberWithCommas(row.ourRate )}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="d-flex small text-dark-50 align-items-center">Per Ct. <span className="font-size-sm text-dark-50 ml-2">{symbol}{numberWithCommas(row.ourRate  * conversionrate)}</span></span>
                                                </OverlayTrigger>
                                            </> :
                                            <span className="d-flex small text-dark-50 align-items-center">Per Ct. <span className="font-size-sm text-dark-50 ml-2">{symbol}{numberWithCommas(row.ourRate  * conversionrate)}</span></span>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-3 d-flex align-items-center">
                            <button type="button" className="btn btn-primary text-nowrap px-12 mr-3" onClick={() => BuyDiamond([{original:row}]) }><BsLightningFill /> {language.MUI_BUYDIAMONDBTN}</button>
                            <button type="button" className="btn btn-light text-nowrap text-dark mr-3" onClick={() => HoldDiamond([{original:row}]) }> 
                                <SVG className="ol mr-2" src={toAbsoluteUrl("/media/svg/icons/Custom/Hold.svg")} width="22.384" height="20.075" />
                                {language.MUI_HOLDDIAMONDBTN}
                            </button>
                            <button type="button" className="btn text-dark text-nowrap btn-light" onClick={(e) => RouteToDetail(row) }> 
                                <svg xmlns="http://www.w3.org/2000/svg" className="mr-2" width="22.384" height="20.075" viewBox="0 0 22.384 20.075">
                                    <g id="ic_view_details" transform="translate(-794 -623.819)">
                                        <path className="cls-1545654" id="Path_28337" data-name="Path 28337" d="M3.485.017a1.07,1.07,0,0,0-.634.436C2.739.627.071,5.681.037,5.781a.983.983,0,0,0,.013.56c.031.081,1.441,2.053,4.779,6.683,4.366,6.06,4.741,6.574,4.823,6.614a.385.385,0,0,0,.467-.093c.146-.141,9.53-12.848,9.624-13.029a.762.762,0,0,0,.068-.558c-.022-.087-.459-.938-1.442-2.808C17.595,1.674,16.933.43,16.9.386a.927.927,0,0,0-.44-.338L16.345,0,9.966,0C5.978,0,3.548,0,3.485.017ZM8.57,1.377,7.275,3.24c-.652.938-1.19,1.7-1.195,1.69S4.927,1.468,4.864,1.266L4.85,1.222H8.68Zm6.53-.049c-.263.749-1.2,3.386-1.2,3.389s-2.717-3.451-2.724-3.475.672-.02,1.979-.02h1.986l-.038.107ZM4.314,3.569c.352,1.065.642,1.941.642,1.948s-.761.013-1.691.013-1.691,0-1.691-.01.466-.894,1.036-1.974c.628-1.192,1.041-1.955,1.05-1.939s.3.894.656,1.961Zm7.117.029L12.924,5.52l-1.433.005H8.619L7.178,5.52,8.542,3.592c.75-1.061,1.37-1.926,1.379-1.923S10.608,2.54,11.431,3.6Zm5.824.064c.53,1.015.963,1.851.963,1.857s-.743.011-1.653.011c-1.563,0-1.652,0-1.643-.036s1.314-3.677,1.326-3.7.015-.01.024,0,.449.849.98,1.863ZM6.7,10.728c1.723,5.2,1.734,5.24,1.705,5.208S2.982,8.417,1.86,6.852l-.068-.094h3.6L6.7,10.728Zm6.452-3.957c0,.042-3.314,9.424-3.324,9.412S6.714,6.8,6.714,6.771,13.155,6.752,13.155,6.771Zm4.9.022c-.284.4-6.788,9.146-6.793,9.141s.719-2.072,1.605-4.6L14.48,6.754h1.8c1.737,0,1.8,0,1.772.036Z" transform="translate(793.998 623.819)" fill="#212529"/>
                                        <path className="cls-1545654" id="eye" d="M11.958,8.205A6.7,6.7,0,0,0,6.015,4.5,6.7,6.7,0,0,0,.073,8.206a.676.676,0,0,0,0,.61,6.7,6.7,0,0,0,5.943,3.705,6.7,6.7,0,0,0,5.943-3.706A.676.676,0,0,0,11.958,8.205ZM6.015,11.518A3.008,3.008,0,1,1,9.023,8.51,3.008,3.008,0,0,1,6.015,11.518Zm0-5.013a1.991,1.991,0,0,0-.529.079,1,1,0,0,1-1.4,1.4A2,2,0,1,0,6.015,6.505Z" transform="translate(804.354 631.374)" fill="#212529"/>
                                    </g>
                                </svg>
                                {language.MUI_DETAILS}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="row">
                        <div className="col-6 border-left pb-10">
                            { (row.isfancy) ? (
                                <>
                                    <div className="font-size-h5 text-dark  mb-3 mt-5">Fancy Color Details </div>
                                    <div className="row mb-1">
                                        <div className="col-6">Intensity</div>
                                        <div className="col-6 ">  {row.fancy_intensity  ? row.fancy_intensity  : "-"} </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-6">Overtone</div>
                                        <div className="col-6 ">  {row.fancy_overtone  ? row.fancy_overtone  : "-"} </div>
                                    </div>
                                    <div className="row mb-5">
                                        <div className="col-6">Color</div>
                                        <div className="col-6 ">  {row.fancy_color  ? row.fancy_color  : "-"} </div>
                                    </div>
                                </>
                            ):('')}
                            <div className="font-size-h5 text-dark font-weight-bold mb-3 mt-5">{language.MUI_PARAM_DETAILS} </div>
                            <div className="row mb-1">
                                <div className="col-6"> {language.MUI_MEASUREMENT} </div>
                                <div className="col-6 "> {row.length?.toFixed(2) } * {row.width?.toFixed(2)} * {row.height?.toFixed(2)} </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6 "> {language.MUI_TABLE} (%) </div>
                                <div className="col-6 "> {row.tablePercent  ? row.tablePercent?.toFixed(2)  : "-"} </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6"> {language.MUI_DEPTH} (%)</div>
                                <div className="col-6 "> {row.depthPercent  ? row.depthPercent?.toFixed(2)  : "-"} </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">
                                    {language.MUI_CRHEIGHT}
                                </div>
                                <div className="col-6 ">
                                    {row.crownHeight ? row.crownHeight?.toFixed(2) : "-"}
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">
                                    {language.MUI_CRANGLE}
                                </div>
                                <div className="col-6 ">
                                    {row.crownAngle ? row.crownAngle : "-"}
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">
                                {language.MUI_PAVHEIGHT}
                                </div>
                                <div className="col-6 ">
                                    {row.pavilionHeight ? row.pavilionHeight?.toFixed(2) : "-"}
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">
                                    {language.MUI_PAVANGLE}
                                </div>
                                <div className="col-6 ">
                                    {row.pavilionAngle ? row.pavilionAngle?.toFixed(2) : "-"}
                                </div>
                            </div>
                            
                            <div className="row mb-1">
                                <div className="col-6">
                                    {language.MUI_RATIO}
                                </div>
                                <div className="col-6 ">
                                {row.ratio?row.ratio.toFixed(2):"-"}
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">
                                    {language.MUI_GIRDLE}
                                </div>
                                <div className="col-6 ">
                                {row.girdle ? row.girdle : "-"}
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">
                                    {language.MUI_GIRDLECONDTION}
                                </div>
                                <div className="col-6 ">
                                {row.girdleCondition  ? row.girdleCondition : "-"}
                                </div>
                            </div>
                            
                        </div>
                        <div className="col-6 border-left">
                                <h5 className="font-size-h5 text-dark font-weight-bold mb-3  mt-5">{language.MUI_ADDITIONALDETAILS}</h5>
                                <div className="row mb-1">
                                    <div className="col-6">
                                        {language.MUI_BRAND}
                                    </div>
                                    <div className="col-6 ">
                                        {row.brand ? row.brand : "-"}
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">
                                        {language.MUI_SHADE}
                                    </div>
                                    <div className="col-6 ">
                                        {row.shade  ? row.shade : "-"}
                                    </div>
                                </div>
                                <hr className="w-75 ml-0"/>
                                <div className="row mb-1">
                                    <div className="col-6">
                                        {language.MUI_MILKY}
                                    </div>
                                    <div className="col-6 ">
                                        {row.milky   ? row.milky  : "-"}
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">
                                        {language.MUI_EYECLEAN}
                                    </div>
                                    <div className="col-6 ">
                                        {row.eyeClean  ? row.eyeClean  : "-"}
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6"> {language.MUI_CULET} </div>
                                    <div className="col-6 "> {row.culet  ? row.culet : "-"} </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">{language.MUI_KEY}</div>
                                    <div className="col-6 ">{row.keyToSymbol ? row.keyToSymbol : "-" }</div>
                                </div>
                                    {row.diamondType === "L" ? 
                                        <>
                                            { (row.growthType) ? (
                                                <div className="row mb-1">
                                                    <div className="col-6"> {language.MUI_GROWTHTYPE} </div>
                                                    <div className="col-6 ">  {row.growthType ? row.growthType : "-"} </div>
                                                </div>
                                            ):('')}
                                            { (row.treatment ) ? (
                                                <div className="row mb-1">
                                                    <div className="col-6"> {language.MUI_TREATMENT} </div>
                                                    <div className="col-6 ">  {row.treatment  ? row.treatment  : "-"} </div>
                                                </div>
                                            ):('')}
                                        </>
                                    :   
                                        <>
                                            { (row.origin) ? (
                                                <div className="row mb-1">
                                                    <div className="col-6"> {language.MUI_ORIGIN} </div>
                                                    <div className="col-6 ">  {row.origin ? row.origin : "-"} </div>
                                                </div>
                                            ):('')}
                                            {/* { (row.brand) ? (
                                                <div className="row mb-1">
                                                    <div className="col-6"> {language.MUI_BRAND} </div>
                                                    <div className="col-6 ">  {row.brand ? row.brand : "-"} </div>
                                                </div>
                                            ):('')} */}
                                            { (row.treatment ) ? (
                                                <div className="row mb-1">
                                                    <div className="col-6"> {language.MUI_TREATMENT} </div>
                                                    <div className="col-6 ">  {row.treatment  ? row.treatment  : "-"} </div>
                                                </div>
                                            ):('')}
                                        </>
                                    }
                            </div>
                    </div>
                </div>
            </div>
        </div>
                    
          {/* {confirmPopupShow === true && <ConfirmGoods show={confirmPopupShow} onHide={handleConfirmPopupClose} {...row} />}  
          {holdPopupShow === true && <HoldPopup show={holdPopupShow} onHide={handleHoldPopupClose} {...row} />}   */}
           <SwalWarn show={swalWarnOpen} message={alertMessage} onClose={() => setSwalWarnOpen(false)} /> 

            <Modal
                show={showHeartImg}
                onHide={handleClose}
                size="lg"
            >
                <Modal.Body className="text-center">
                    <img src={row.heart} alt={row.shape} className="img-fluid" />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={showArrowImg}
                onHide={handleClose}
                size="lg"
            >
                <Modal.Body className="text-center">
                    <img src={row.arrow} alt={row.shape} className="img-fluid" />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={showAssetImg}
                onHide={handleClose}
                size="lg"
            >
                <Modal.Body className="text-center">
                    <img src={row.asset} alt={row.shape} className="img-fluid" />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                    Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <VideoIframe
                showVideo={showVideo}
                handleClose={handleClose}
                src={row}
                // src={row.ext_video || ""}
                data={row}
           />
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={Toast}
                autoHideDuration={2000}
                onClose={handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={handleClose}
                    variant="success"
                    message={wishlistmessage}
                />
            </Snackbar>
      </>
    )
}
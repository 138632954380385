/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useState}from "react";
import { FaLongArrowAltUp ,FaLongArrowAltDown} from "react-icons/fa";
import { useHistory } from "react-router-dom";
// import {Modal,Button} from "react-bootstrap";
import { Link } from "react-router-dom";
import { SwalWarn } from "../../../Popup";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_helpers";
import { ImageExpandPopupCommon } from "../../tiles/ImageExpandPopupCommon";
import CertificateLink from "../../../CertificateLink";

export const Tracklist = (props) => {
    const [swalWarnOpen,setSwalWarnOpen] = useState(false)
    const [alertMessage,setAlertMessage] = useState()
    const history = useHistory()


    const DetailSemiAprrove = () => {
        setSwalWarnOpen(true)
        setAlertMessage("Your account is under Lite Mode, to get further access please contact your sales representative. Thank you")
    }
    function numberWithCommas(x) {
        if(!x){
            return "0.00"
        }
        return parseFloat(x).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    const getshortlistedsymbol = (responsecurrency) => {
        let symbol = "$"
        if(responsecurrency === "INR"){
          symbol = "₹"
        }
        if(responsecurrency === "USD"){
          symbol = "$"
        }
        if(responsecurrency === "AUD"){
          symbol = "$"
        }
        if(responsecurrency === "CAD"){
          symbol = "$"
        }
        if(responsecurrency === "HKD"){
          symbol = "$"
        }
        if(responsecurrency === "CNY"){
          symbol = "¥"
        }
        if(responsecurrency === "EUR"){
          symbol = "€"
        }
        if(responsecurrency === "GBP"){
          symbol = "£"
        }
        if(responsecurrency === "NZD"){
          symbol = "$"
        }
        if(responsecurrency === "JPY"){
          symbol = "¥" 
        }
        if(responsecurrency === "CHF"){
          symbol = "₣"
        }
        if(responsecurrency === "SGD"){
            symbol = "$"
            
        } if(responsecurrency === "THB"){
            symbol = "฿"
            
        } if(responsecurrency === "MYR"){
            symbol = "RM"
            
        } if(responsecurrency === "BND"){
            symbol = "$"
            
        } if(responsecurrency === "DKK"){
            symbol = "kr."
            
        } if(responsecurrency === "SEK"){
            symbol = "kr"
            
        }
        return symbol
      }

    return (
        <>
            {/* {props ? console.log("props", props) : null} */}
            <div className={`row border-bottom align-items-center position-relative ${props.stoneStatus !==1 ? "cursor-na" : ""}`}>
                {props.stoneStatus !== 1? <span className="CanNotHold label label-outline-danger label-pill label-inline bg-danger-o-30 px-5">Sold Out</span> :""}
                <div className={`col-md-8 mb-3 mt-2 ${props.stoneStatus !==1 ? "CanNotHoldDiv" : ""}`}>
                    <div className="d-flex">
                        <div className="text-center">
                        {props.image?<ImageExpandPopupCommon data={props}/>:<img
                                    className="img-fluid mr-2"
                                    alt={props.shape}
                                    src={toAbsoluteUrl(
                                        `/media/shape/${props.shape ? props.shape.toLowerCase() : ""}-no.png`
                                    )}
                                    width="50px"
                                    style={{ backgroundColor: "#f4f4f4" }}
                                />}
                        </div>
                        <div className="pl-2 p-0 text-left">
                            <div className={`mb-1 ${props.diamondType === "N" || props.diamondType === "W"  ? "natTab" : "labTab"} font-weight-bold`}>
                                <span className={`${ props.diamondType === "N" || props.diamondType === "W" ? props.isfancy ? "F" : "N" : props.isfancy ? "LF" : "L"  }_diamond mr-3 small`}>
                                    {props.diamondType === "N" || props.diamondType === "W" ? props.isfancy  ? "NF" : "N" : props.isfancy ? "LF" : "L"}
                                </span>
                                {/* {props.stoneStatus ===1 ? 
                                    <span onClick={() => history.push({ pathname: `/detail/${props.diamondType === "L"?"L":"N"}-${props.stockId}` })} className={`text-primary text-hover-primary cursor-pointer small ml-1`}>
                                        {props.diamondType === "N" || props.diamondType === "W" ? "N" : "L"}-{props.stockId}
                                    </span>
                                : 
                                    <span className={`text-primary small ml-1`}>
                                        {props.diamondType === "N" || props.diamondType === "W" ? "N" : "L"}-{props.stockId}
                                    </span>
                                } */}
                                {(props.userinfo?.v_customer?.toString() ==="7" || props.userinfo?.v_customer?.toString() ==="11") ? 
                                    <span className="text-primary small cursor-pointer" onClick={(e) => DetailSemiAprrove()}>
                                        {props.diamondType === "N" || props.diamondType === "W"? "N" : "L"}-{props.stockId}
                                    </span>
                                : props.stoneStatus ===1 ?
                                    <Link className="text-primary text-hover-primary small" onClick={() => history.push({ pathname: `/detail/${props.diamondType === "L"?"L":"N"}-${props.stockId}` })}>
                                        {props.diamondType === "N" || props.diamondType === "W" ? "N" : "L"}-{props.stockId}
                                    </Link> : 
                                    <span className="text-primary small">
                                        {props.diamondType === "N" || props.diamondType === "W" ? "N" : "L"}-{props.stockId}
                                    </span>
                                }
                                
                                <CertificateLink Certificate_link={props.Certi_link} lab={props.lab} certificate={props.certificate} className={`mx-2 text-primary small`} />
                            </div>

                            <span className="text-dark font-weight-bold d-block">
                                {props.shape}{" "}
                                {parseFloat(props.carat).toFixed(2)}{" "}
                                {props.color}
                                {props.clarity}{" "}{props.cut}{" "}{props.polish}{" "}{props.symmetry}{" "}
                                {props.fluorescence}
                            </span>
                        </div>
                    </div> 
                </div>
                <div className={`col-md-4 text-right d-flex justify-content-end  ${props.stoneStatus !==1 ? "CanNotHoldDiv" : ""}`}>
                    <div>
                        <div className="text-muted font-weight-bold">
                            {props.symbol}/CT
                            {
                                props.stoneStatus === 1 ?
                                    props.conversionrate !== 1 ? 
                                        <>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip id="tooltip">
                                                        ${numberWithCommas(props.calculateprice.T_C_Rate)}
                                                    </Tooltip>
                                                }
                                            >
                                                <span> {props.symbol}{numberWithCommas(props.calculateprice.T_C_Rate * props.conversionrate)}</span>
                                            </OverlayTrigger>
                                        </> 
                                    :
                                        <span className="ml-1"> {props.symbol}{numberWithCommas(props.calculateprice.T_C_Rate * props.conversionrate)}</span>
                                :null
                            }
                        </div>
                        <div className="text-dark font-weight-bold">
                            { props.stoneStatus === 1 ?
                                props.conversionrate !== 1 ? 
                                    <>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip id="tooltip">
                                                    ${numberWithCommas(props.calculateprice.total_our_price)}
                                                </Tooltip>
                                            }
                                        >
                                            <span> { props.symbol}{numberWithCommas(props.calculateprice.total_our_price *  props.conversionrate)}</span>
                                        </OverlayTrigger>
                                    </>
                                :
                                    <span> { props.symbol}{numberWithCommas(props.calculateprice.total_our_price *  props.conversionrate)}</span>
                                : null
                            }
                        </div>
                    </div>
                    <div>
                        { 
                            props.tracklisted_price_usd * props.currency_rate === props.calculateprice.total_our_price * props.currentCurrRate
                            ? null
                            : props.tracklisted_price_usd * props.currency_rate < props.calculateprice.total_our_price * props.currentCurrRate
                            ?
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="tooltip">
                                            Tracklisted Cost {getshortlistedsymbol(props.currency)}/CT <b>{getshortlistedsymbol(props.currency)}{numberWithCommas(props.tracklisted_price_usd/props.carat * props.currency_rate)} </b>
                                            <br/>Tracklisted Cost price<b> {getshortlistedsymbol(props.currency)}{numberWithCommas(props.tracklisted_price_usd * props.currency_rate)}</b> 
                                        </Tooltip>
                                    }
                                >
                                    <span><FaLongArrowAltUp className='text-danger cursor-pointer font-size-h3' /></span>
                                </OverlayTrigger>
                            :
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="tooltip">
                                            Tracklisted Cost {getshortlistedsymbol(props.currency)}/CT <b>{getshortlistedsymbol(props.currency)}{numberWithCommas(props.tracklisted_price_usd/props.carat * props.currency_rate)} </b>
                                            <br/>Tracklisted Cost price<b> {getshortlistedsymbol(props.currency)}{numberWithCommas(props.tracklisted_price_usd * props.currency_rate)}</b> 
                                        </Tooltip>
                                    }
                                >
                                    <span><FaLongArrowAltDown className='text-success cursor-pointer  font-size-h3' /></span>
                                </OverlayTrigger>
                        }
                    </div>
                    {/* <div className="text-danger font-weight-bold"><FaLongArrowAltUp /> {props.pricehike}</div> */}
                </div>
            </div>
            <SwalWarn show={swalWarnOpen} message={alertMessage} onClose={() => setSwalWarnOpen(false)} /> 
        </>
    );
};

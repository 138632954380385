import React, { useState,useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { useFormik } from "formik";
import Cookies from "js-cookie";
import * as Yup from "yup";
import { NODE_API_URL,BASE_URL } from "../../../env_config";
import { toast } from "react-toastify";
import Autosuggest from "react-autosuggest";
export const TracklistStone = (props) => {
    const handleClose = () => props.setShow(false);

    const initialValues = {
        fullName: "",
        emailAddress: "",
        contactNumber: "",
        comment: "",
    };

    const Schema = Yup.object().shape({
        fullName: Yup.string().required("Tracklist Name is required"),
        // comment: Yup.string().required("Notes is required").max(255, 'Comment must be at most 255 characters'),
    });

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
            const TracklistObject = {
                "user_id": props.userinfo.id,
                "currency": props.symbolname,
                "currency_rate": props.conversionrate,
                "tracklist_name": values.fullName,
                "notes": values.comment,
                "diamonddetails": props.diamonddetails
            };

            axios({
                method: "POST",
                url: `${NODE_API_URL}/EUR/tracklistDiamond`,
                headers: {
                    "Content-Type": "application/json",
                     "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
                },
                data: TracklistObject,
            }).then(res => {
                if (res && res.data) {
                    toast.success(res.data)
                    props.setShow(false);
                    resetForm();
                }
            }).
            catch(error => {
              if(error?.response?.data?.message === "UnAuthorized!"){
                var cookies = document.cookie.split(";");
              for (var i = 0; i < cookies.length; i++) {
                if(cookies[i] && !cookies[i].includes("view")){
                  var equals = cookies[i].indexOf("=");
                  var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                  document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                }
              }
              
              window.location.replace(`${BASE_URL}/logout`);
            }
            })
        }
    });

    const [suggestions, setSuggestions] = useState([]);
    const [value, setValue] = useState("");

    const [staticSuggestions,setStaticSuggestion] = useState([]);
    useEffect(() => {
        if(props.show){
    
            axios({
                method: "GET",
                url: `${NODE_API_URL}/EUR/fetchTracklistSuggestionList`,
                headers: {
                    "Content-Type": "application/json",
                     "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
                }
            }).then(res => {
                if (res && res.data && res.data.data) {
                    setStaticSuggestion(res.data.data)
                }
            });
        }
    },[props.show])
    const getSuggestions = (inputValue) => {
        const filteredSuggestions = staticSuggestions.filter((suggestion) =>
            suggestion.name.toLowerCase().includes(inputValue.trim().toLowerCase())
        );
        setSuggestions(filteredSuggestions);
    };

    const onChange = (event, { newValue }) => {
        setValue(newValue);
    };

    const onSuggestionsFetchRequested = ({ value }) => {
        getSuggestions(value);
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const onSuggestionSelected = (event, { suggestion }) => {
        formik.setFieldValue("fullName", suggestion.name); // Update Formik field value
    };

    const inputProps = {
        placeholder: props.language.TRACKLIST_STONE_POPUP_TRACKLIST_NAME,
        className: `form-control ${formik.touched.fullName && formik.errors.fullName ? "is-invalid" : ""}`,
        name: "fullName",
        value: formik.values.fullName,
        onChange: formik.handleChange, // Use Formik's handleChange function
    };

    return (
        <Modal
            show={props.show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>{props.language.TRACKLIST_STONE_POPUP_TRACKLIST_STONE}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="row" onSubmit={formik.handleSubmit}>
                    <div className="col-12">
                        <div className="form-group row mb-2">
                            <label className="col-12 col-form-label text-alert font-weight-bold pb-1">
                                {props.language.TRACKLIST_STONE_POPUP_TRACKLIST_NAME} <span className="small text-danger">*</span>
                            </label>
                            <div className="col-12">
                                <Autosuggest
                                    suggestions={suggestions}
                                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                                    getSuggestionValue={(suggestion) => suggestion.name}
                                    renderSuggestion={(suggestion) => <div>{suggestion.name}</div>}
                                    inputProps={inputProps}
                                    onSuggestionSelected={onSuggestionSelected}
                                />
                                {formik.touched.fullName && formik.errors.fullName ? (
                                    <div className="invalid-feedback">{formik.errors.fullName}</div>
                                ) : null}
                            </div>
                        </div>
                        <div className="form-group row mb-2">
                            <label className="col-12 col-form-label text-alert font-weight-bold pb-1">
                                {props.language.TRACKLIST_STONE_POPUP_TRACKLIST_NOTES} <span className="small text-danger">*</span>
                            </label>
                            <div className="col-12">
                                <textarea
                                    placeholder={props.language.TRACKLIST_STONE_POPUP_TRACKLIST_NOTES}
                                    className={`form-control ${formik.touched.comment && formik.errors.comment ? "is-invalid" : ""}`}
                                    rows="4"
                                    name="comment"
                                    {...formik.getFieldProps("comment")}
                                />
                                {formik.touched.comment && formik.errors.comment ? (
                                    <div className="invalid-feedback">
                                        {formik.errors.comment}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 border-top pt-5 mt-5 d-flex justify-content-between">
                        <button
                            className="btn btn-secondary btn-sm mr-2"
                            onClick={handleClose}
                        >
                            {props.language.CONFIRM_GOODS_POPUP_CLOSE}
                        </button>
                        <button
                            type="submit"
                            className="btn btn-primary btn-sm"
                        >
                            {props.language.TRACKLIST_STONE_BTN}
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};
